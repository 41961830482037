import { useState, useEffect } from "react";

import { useMap } from "react-leaflet";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import L from "leaflet";

import icon from "./iconSearch";

const layerGroup = L.layerGroup();

const LeafletControlGeocoder = ({ data }) => {
  const map = useMap();

  let [printInfo, setPrintinfo] = useState("");
  useEffect(() => {
    layerGroup.clearLayers();
  }, [data]);
  useEffect(() => {
    if (map) {
      layerGroup.clearLayers();
      let geocoder = L.Control.Geocoder.nominatim({
        geocodingQueryParams: {
          limit: 3,
          addressdetails: 1,
          namedetails: 1,
          countrycodes: "kz",
        },
      });

      L.Control.geocoder({
        query: "",
        placeholder: "Введите адрес",
        defaultMarkGeocode: false,
        geocoder,
      })
          .on("markgeocode", function (e) {
            layerGroup.clearLayers();
            let latlng = e.geocode.center;
            L.marker(latlng, { icon })
                .addTo(map)
                .bindPopup(e.geocode.name)
                .openPopup()
                .addTo(layerGroup);

            map.panTo(latlng);

            setPrintinfo(e.geocode.name);

            map.addLayer(layerGroup);
          })
          .addTo(map);
    }
  }, [map]);

  return <div className="printInfo">{printInfo}</div>;
};
export default LeafletControlGeocoder;
