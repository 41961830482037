import React, { useEffect, useState } from "react";
import "../Style/Map.css";
import "leaflet/dist/leaflet.css";
import {MapContainer, Polygon, Popup, TileLayer, useMap} from "react-leaflet";
import "../Style/iconStyle.css";

import "antd/dist/reset.css";

import LeafletControlGeocoder from "../Elements/searchItem";


import {ModifiedArray} from "../../../utils/helper";


import PopupItem from "../Elements/popupItem";
import {lanGs} from "../../../utils/langData";
import {Drawer} from "antd";

const Map = ({data, toMark, setToMark, cardHow, lang, setCardSelect, cardSelect,setCardHow, setOpen, mobile
}) => {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState([43.248500, 76.897338]);

  /*
   ;*/
  let SetViewOnClick = () => {
    const map = useMap();
    // toMark && map.flyTo(toMark, 12);
    toMark && map.setView([toMark[0] + 0.02, toMark[1] + 0.08], !mobile ? 11: 10);

   /* useEffect(() => {
      map.eachLayer(function (layer) {
        layer.closePopup();
      });

      toMark && map.flyTo(toMark, 13);
    }, [cardSelect]);*/
    useEffect(() => {
      !cardSelect && !toMark && map.closePopup();


      toMark &&   map.openPopup(
          lang === 'nameRu'? lanGs['Избирательный округ'][lang]  +' №' + cardSelect.regionNumber : ' №' + cardSelect.regionNumber + ' ' + lanGs['Избирательный округ'][lang],
          toMark?.map((i) => i)
      );
    }, [map]);
  };
/*  const bounds = [
    [43.088884, 76.757455], // Юго-западные координаты границы
    [43.416873, 77.154205], // Северо-восточные координаты границы
  ];*/

  return (
    <div className={"Map_wrapper"}>

      <MapContainer
        style={{ width: "100%", height: "100%" }}
        className="markercluster-map"
        id="mapMain"
        center={center}
        zoom={!mobile ? 11: 10}
        minZoom={10}
        zoomControl={true}
        scrollWheelZoom={true}
        fadeAnimation={true}
        markerZoomAnimation={true}
        whenCreated={setMap}
        fullscreenControl={true}
   /*     maxBounds={bounds}*/
        maxBoundsViscosity={.1}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <SetViewOnClick coords={toMark} />
        <LeafletControlGeocoder setToMark={setToMark} lang={lang} data={data} />
        {data && data.map((i, index )=> {
          return <Polygon
              key={index + 'mapKey' + i.id}
            pathOptions={{fillOpacity:  `${cardHow === i?.regionNumber? .5:.2}`, weight: 2, opacity: .6, color: colors[index]}}
            positions={ModifiedArray(i?.geom?.coordinates)}
            eventHandlers={{
              click: (e) => {
                setCardSelect(null);
                setToMark(null);
                setCardSelect(i);
                setCardHow(i.regionNumber)
                !mobile && setOpen(true)
              },
            }}
            className={`Polygon_dist ${i.id} ${cardSelect ? cardSelect.regionNumber: ''}`}
        >
          <Popup key={i.id + "props marker"}>
            <PopupItem data={i} lang={lang}/>
          </Popup>
        </Polygon>})}
      </MapContainer>

    </div>
  );
};

export default Map;
let colors = [
  '#E7D541',
  '#E2A836',
  '#DA8863',
  '#DA661B',
  '#D37DB0',
  '#CD3222',
  '#C1C088',
  '#B82035',
  '#97C7E8',
  '#90B543',
  '#8F0487',
  '#801716',
  '#7F7E83',
  '#6E3815',
  '#6C0888',
  '#60AC49',
  '#5165B3',
  '#4B1091',
  '#2C8D09',
  '#2A3815',
  '#1F46AA',
]
